//BTOCSITE-12128 메인성능개선 - 스크립트 구조 변경
(function(global){
    var script = {
        name : "support-main",
        hash : 'support'
    };

    if(global[script.name]) return; // 중복로딩 차단 
    var detect = vcui.detect;
    var isMobileDevice = detect.isMobileDevice;    
    var $context = $(document);//isMobileDevice ? $('[data-hash="support"]') : $(document);

    var supportHome = {
        loginTooltip : function(){
            var $tooltip = $('.support-home').find('.tooltip-login');
            var $btnClose = $tooltip.find('.btn-tooltip-close');

            $tooltip.on('click', function() {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainLoginClick.do', '/acecount/mainLoginClickm.do');
            });

            $btnClose.on('click', function(e){
                $tooltip.hide();
                e.preventDefault();
            });
        },
        toggleList : {
            el : {
                container : '[data-toggle-list="container"]',
                wrap : '[data-toggle-list="wrap"]',
                list : '[data-toggle-list="list"]',
                btn : '[data-toggle-list="btn"]'
            },
            toggle : function(){
                var self = this;

                $context.on('click', self.el.btn, function(e){
                    var $this = $(this);
                    var $listWrap = $this.closest(self.el.wrap);
                    var $list = $listWrap.find(self.el.list);
                    e.preventDefault();
    
                    if( $listWrap.hasClass('active')) {
                        $this.find('.blind').text($this.find('.blind').text().replace('접기', '펼치기'));
                        $listWrap.removeClass('active').siblings().removeClass('siblings');
                        $list.stop().slideUp();
                    } else {
                        $this.find('.blind').text($this.find('.blind').text().replace('펼치기', '접기'));
                        $listWrap.removeClass('siblings').addClass('active').siblings().removeClass('active').addClass('siblings').find(self.el.list).stop().slideUp(function(){
                            $(this).attr('style', '');
                        });
                        $list.stop().slideDown();
                    }
                    
                })
            },
            init : function(){
                this.toggle();
            }
        },
        moreShow : {
            el : {
                container : '[data-more="container"]',
                hidden : '[data-more="hidden"]',
                btn : '[data-more="btn"]'
            },
            btnShow : function(){
                var self = this;

                $context.find(self.el.container).each(function(){
                    var $this = $(this);
                    var $item = $this.find(self.el.hidden);

                    if( $item.length ) {
                        $this.find(self.el.btn).css('display','block');
                    } else {
                        $this.find(self.el.btn).css('display','none');
                    }
                })
            },
            hiddenVisible : function(){
                var self = this;
                var $moreBtn = $context.find(self.el.btn);

                $context.find(self.el.container).each(function(){
                    var $this = $(this);
                    var $item = $this.find('.item');
                    var $itemList = $this.find('.item-list');

                    $item.each(function(i){
                        if( i >= 3) {
                            $(this).addClass('hidden').data('more', 'hidden').attr('data-more', 'hidden');
                        }
                    })

                    $itemList.each(function(i){
                        if( i >= 3) {
                            $(this).addClass('hidden').data('more', 'hidden').attr('data-more', 'hidden');
                        }
                    })
                })

                $moreBtn.on('click', function(e){
                    var $this = $(this);
                    var $wrap = $this.closest(self.el.container);
                    var $hiddenItem = $wrap.find(self.el.hidden);
                    
                    if( $hiddenItem.filter('.show').length ) {
                        $hiddenItem.removeClass('show');
                        $this.removeClass('close').text('더보기');
                    } else {
                        $hiddenItem.addClass('show');
                        $this.addClass('close').text('접기');
                    }
                    
                    e.preventDefault();
                })

                self.btnShow();
            },
            init : function(){
                this.hiddenVisible();
            }
        },
        slide : {
            activeClass : ".slick-initialized",
            controls : {
                play : $context.find('.btn-play')
            },

            notice : {
                el : {
                    slider : $context.find('.notice-slider')
                },                
                config : {
                    infinite: true,
                    autoplay: true,
                    dots : false,
                    swipe : true,
                    arrows : false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    vertical:true,
                    verticalSwiping: true,
                    cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
                    speed: 150,
                    touchThreshold: 100
                },
            },
            service : {
                el : {
                    slider : $('.main-service-slider')
                },                
                config : {
                    infinite: true,
                    autoplay: false,
                    arrows : true,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
                    speed: 150,
                    touchThreshold: 100
                }, 
            },
            related : {
                el : {
                    slider : $('.related-slider')
                },                
                config : {
                    infinite: true,
                    autoplay: true,
                    arrows : false,
                    dots : true,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
                    speed: 150,
                    touchThreshold: 100
                },
            },
            product : {
                el : {
                    slider : $('.own-product-wrap .swiper-container')
                },                
                config : {
                    effect: 'fade',
                    observer: true,
                    observeParents: true,
                    slidesPerView: 3,
                    spaceBetween: 40,
                    centeredSlides: false,
                    loopFillGroupWithBlank : true,
                    speed:  200,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                },
            },
            resize : function(){
                var self = this;

                if( window.innerWidth > 1024) {
                    $context.find('.support-toggle-list-wrap').not('.only-desktop').addClass('only-desktop');
                    self.supportList.el.slider.filter('.slick-initialized').slick('unslick');
                } else {
                    $context.find('.support-toggle-list-wrap').removeClass('only-desktop');
                    self.supportList.el.slider.not(self.activeClass).slick(self.supportList.config);
                }
                
            },
            _initSwiper: function(swiper){
                var self = this;
                var $swiper = $(swiper.el);

                
                //button
                $swiper.find('.product-item').each(function() {
                    var $item = $(this);
                    $item.on('click', function(e) {
                        // e.preventDefault();
                        // e.stopPropagation();

                        //GA태깅
                        $swiper.find('.product-item').each(function(index, item){
                            var gaText = $(item).attr('data-contents');
                            if(!!gaText) {
                                if(gaText.indexOf('링크') > -1){
                                    $(item).attr('data-contents', gaText.replace('링크', '선택'));
                                }
                            }
                        });
                        //GA태깅
                        var gaText = $item.attr('data-contents');
                        if(!!gaText){
                            $item.attr('data-contents', gaText.replace('선택', '링크')); 
                        }
                        
                        var activeIdx = swiper.clickedIndex;
                        var openIdx = $swiper.find('.product-item.open').index();
                        var $this = $swiper.find('.product-item').eq(activeIdx);
                        var data = $item.find(".btn-open").data();
                        
                        if(activeIdx == openIdx) {
                            // PDP가 없는 제품은 PSP로 이동
                            if(!!data.pdpUrl){
                                location.href = data.pdpUrl;
                            }else{
                                location.href = data.pspUrl;
                            }
                            return;
                        }
                        

                        //제품등록 버튼 리턴
                        var btnAdd = $this.find('.btn-add').length;
                        if(btnAdd) {
                            return;
                        }

                        // self.requestQnaList(data);
                        self.requestUpdateLastModel(data);
                        self.requestServiceList(data);

                        $swiper.find('.product-item').removeClass("open");
                        $this.addClass('open');

                    });
                });

                
            }, 
            requestServiceList: function(_data){
                var self = this;
                
                var serviceTmpl = 
                '<div class="list-wrap">'+
                '<ul data-contents="{{modelCode}}">'+
                '{{#each (item, index) in data.serviceMenuList}}' +
                    '{{#if item.name == "출장 서비스 예약"}}'+
                        '<li class="item"><a href="{{item.url}}" class="icon-reserv">출장 서비스 예약</a></li>'+
                    '{{#elsif item.name == "예약 조회/변경"}}'+
                        '<li class="item"><a href="{{item.url}}" class="icon-result">예약 조회/ 변경</a></li>'+
                    '{{#elsif item.name == "서비스 전문 상담"}}'+
                        '<li class="item"><a href="{{item.url}}" class="icon-contact">서비스 전문 상담</a></li>'+
                    '{{#elsif item.name == "다운로드 센터"}}'+
                        '<li class="item"><a href="{{item.url}}" class="icon-manual">다운로드 센터</a></li>'+
                    '{{#elsif item.name == "센터 방문 예약"}}'+
                        '<li class="item"><a href="{{item.url}}" class="icon-visit">센터 방문 예약</a></li>'+
                    '{{#elsif item.name == "케어용품/소모품"}}'+
                        '<li class="item"><a href="{{item.url}}" class="icon-goods">케어용품/소모품</a></li>'+
                    '{{#elsif item.name == "가전 세척"}}'+
                        '<li class="item"><a href="{{item.url}}" class="icon-wash">가전 세척</a></li>'+
                    '{{#elsif item.name == "이전 설치"}}'+
                        '<li class="item"><a href="{{item.url}}" class="icon-removal">이전 설치</a></li>'+
                    '{{/if}}'+
                '{{/each}}' +
                '</ul>'+
                '</div>';
    
    
                var suppliesTmpl = 
                '<h5 class="supplies-title">내 제품에 딱 맞는 소모품</h5>'+
                '<div class="supplies-list">'+
                '<ul data-contents="{{modelCode}}">'+
                    '{{#each (item, index) in suppliesList}}' +
                    '<li class="item">'+
                    '<p class="prd-img">'+
                        '<a href="{{item.url}}"><span class="blind">제품상세 바로가기</span></a>'+
                        '{{#if item.buyDisposableCnt}}'+
                            '<em class="flag">{{item.buyDisposableCnt}}회 구매</em>'+
                        '{{/if}}'+
                        
                        '{{#if item.promotionFlag}}'+
                            '<em class="flag red">프로모션</em>'+
                        '{{/if}}'+

                        '<img src="{{item.imageUrl}}" alt="{{item.imageAlt}}">'+
                    '</p>'+
                    '<p class="prd-name">{{item.title}}</p>'+
                    '<p class="model-code">{{item.sku}}</p>'+
                    '<p class="price">{{item.price}}</p>'+
                    '<p class="cart" data-contents="{{modelCode}}-{{item.sku}}">'+
                        '{{#if item.soldoutFlag == "N"}}'+
                            '<button type="button" class="btn-cart" data-id="{{item.modelId}}" data-sku="{{item.sku}}"><span class="blind">장바구니 담기</span></button>'+
                        '{{#else}}'+
                            '<span class="soldoutFlag">일시품절<span>'+
                        '{{/if}}'+
                    '</p>'+
                    '</li>'+
                    '{{/each}}' +
                '</ul>'+
                '</div>';
    
    
                var recommendTmpl = 
                '<h5 class="recommend-title">내 제품에 꼭 필요한 정보</h5>'+
                '<ul class="recommend-list" data-contents="{{modelCode}}">'+
                '{{#each (item, index) in data.recommendList}}' +
                    '<li>'+
                    '<a href="/support/solutions-{{item.item_id}}">'+
                        '{{#if index != 1}}'+
                            '<em class="flag">HOT</em>'+
                        '{{#else}}'+
                            '<em class="flag">NEW</em>'+
                        '{{/if}}'+
                        '{{item.item_title}}'+
                    '</a>'+
                    '</li>'+
                '{{/each}}' +
                '</ul>';
    
    
                // var $selectBox = self.$ownProductWrap.find('[data-model-code="'+_data.modelCode+'"]').parents('.product-box');
                // var $selectCont = $selectBox.find('.prd-cont');
    
                
                // var isHas = !!$selectCont.has(".home-links-wrap").length || !!$selectCont.has(".supplies-wrap").length || $selectCont.has(".recommend-wrap").length;
                // if(isHas) return;
                
                //보유제품
                self.$ownProductWrap = $(".own-product-wrap");
                var $prdCont = self.$ownProductWrap.find('.product-content');
                
                var url = self.$ownProductWrap.data('ajax');
                var param = {
                    modelCode : _data.modelCode
                };
                lgkorUI.showLoading();
                lgkorUI.requestAjaxDataPost(url, param, function(result) {
    
                    var data = result.data,
                        isServiceMenu = Object.keys(data).includes('serviceMenuList'),
                        isSupplies = Object.keys(data).includes('suppliesList'),
                        isRecommend = Object.keys(data).includes('recommendList'),
                        arrServiceMenuList = data.serviceMenuList instanceof Array ? data.serviceMenuList : [],
                        arrSuppliesList = data.suppliesList instanceof Array ? data.suppliesList : [],
                        arrRecommendList = data.recommendList instanceof Array ? data.recommendList : [],
                        seviceHtml = "",
                        suppliesHtml = "",
                        recommendHtml = "";

    
                    if(result.status == 'success'){
                        //서비스메뉴
                        $prdCont.find('.lft-cont').empty();
                        $prdCont.find('.rgt-cont').empty();

                        if(isServiceMenu && arrServiceMenuList.length > 0) {
                            $prdCont.find('.lft-cont').append('<div class="home-links-wrap"></div>');
                            seviceHtml = vcui.template(serviceTmpl, {data: data, modelCode : _data.modelCode});
                            $prdCont.find('.home-links-wrap').empty().html(seviceHtml); 
                             
                        }
                        //연관소모품
                        if(isSupplies && arrSuppliesList.length > 0) {
                            var suppliesArr = vcui.array.map(data.suppliesList, function(item, index) {
                                if(item.price) {
                                    item.price = item.price > 0 ? vcui.number.addComma(item.price) + "원" : '';
                                }
                                if(item.category){
                                    item.promotionFlag = item.category.includes('LG UP Kit') ? 'Y' : '';
                                }
                                return item;
                            });

                            $prdCont.find('.rgt-cont').append('<div class="supplies-wrap"></div>');
                            suppliesHtml = vcui.template(suppliesTmpl, {suppliesList: suppliesArr, modelCode : _data.modelCode});
                            $prdCont.find('.supplies-wrap').empty().html(suppliesHtml); 
                        }else {
                            $prdCont.find('.rgt-cont').append('<div class="supplies-wrap"></div>');
                            suppliesHtml = '<h5 class="supplies-title">내 제품에 딱 맞는 소모품</h5><div class="no-data">소모품이 없습니다</div>'
                            $prdCont.find('.supplies-wrap').empty().html(suppliesHtml); 
                        }
                        //추천콘텐츠
                        if(isRecommend && arrRecommendList.length > 0) {
                            $prdCont.find('.rgt-cont').append('<div class="recommend-wrap"></div>');
                            recommendHtml = vcui.template(recommendTmpl, {data : data, modelCode : _data.modelCode});
                            $prdCont.find('.recommend-wrap').empty().html(recommendHtml); 
                        }
                    }
                });
    
            },
            requestQnaList: function(_data){
                var template = 
                    '{{#each (item, index) in listData}}'+
                    '<li class="item">'+
                    '<a href="/support/solutions-{{item.item_id}}">'+
                        '<p><span class="icon"><img src="{{item.icon_path}}" alt="{{item.cate_name}}"></span></p>'+
                        '<p class="type">{{item.symp_name}}</p>'+
                        '<p class="title">'+
                            '<span class="txt">{{item.item_title}}</span>'+
                            '{{#if item.video_flag == "Y"}}'+
                                '<span class="icon-movie"><em class="blind">동영상 컨텐츠</em></span>'+
                            '{{/if}}'+
                        '</p>'+
                        '<p class="date">{{item.regist_date}}</p>'+
                    '</a>'+
                    '</li>'+
                    '{{/each}}';
                
                var $popularListWrap = $context.find('.popular-list-wrap');
                var subCategory = _data.subCategory;
                var url = $popularListWrap.data('ajax');
                    url = url + '?cateList=' + subCategory; //"/support/cateContents.lgajax?cateList="+subCategory;
                
                lgkorUI.showLoading();
                lgkorUI.requestAjaxData(url, {}, function(result) {
    
                    var data = result.data,
                        dateArr = data.listData instanceof Array ? data.listData : [],
                        html = "";
    
                    if(data.resultFlag == 'Y'){
                        if(!!dateArr.length) {
                            var lng = dateArr.length > 3 ? 3 : dateArr.length;
                            //3개 노출
                            var dataObj = {listData : dateArr.splice(0,3)};
                            html += vcui.template(template, dataObj);
    
                            $popularListWrap.find('.popular-list').empty().html(html);   
                        }
                    }
                });
    
            },
            requestUpdateLastModel: function(_data){
                
                var modelCode = _data.modelCode;
                var url = "/support/updateLastChoiceModel.lgajax?selectedModel="+modelCode;
    
                lgkorUI.showLoading();
                lgkorUI.requestAjaxData(url, {}, function(result) {
                    //console.log(result.status);
                });
            },
            // firstInit : function(){
            //     if( window.innerWidth > 1024) {
            //         $context.find('.support-toggle-list-wrap').addClass('only-desktop');
            //     } else {
            //         $context.find('.support-toggle-list-wrap').removeClass('only-desktop');
            //     };
            // },
            init : function(){
                var self = this;

                
                if(!!$('.own-product-wrap').length){
                    new Swiper('.own-product-wrap .swiper-container', {
                        slidesPerView: 3,
                        spaceBetween: 24,
                        allowTouchMove: true,
                        preventClicks: true,
                        preventClicksPropagation: true,
                        touchStartForcePreventDefault: true,
                        speed:  100,
                        navigation: {
                            nextEl: '.own-product-wrap .swiper-controller .swiper-button-next',
                            prevEl: '.own-product-wrap .swiper-controller .swiper-button-prev',
                        },
                        on: {
                            init: function(swiper) {
                                self._initSwiper(swiper);
                            }
                            
                        }
                    });
                }


                if(!!$('.recently-reserv-wrap').length){
                    new Swiper('.recently-reserv-wrap .swiper-container', {
                        slidesPerView: 2,
                        spaceBetween: 24,
                        allowTouchMove: true,
                        preventClicks: true,
                        preventClicksPropagation: true,
                        touchStartForcePreventDefault: true,
                        speed:  100,
                        navigation: {
                            nextEl: '.recently-reserv-wrap .swiper-controller .swiper-button-next',
                            prevEl: '.recently-reserv-wrap .swiper-controller .swiper-button-prev',
                        }
                    });
                }

                $context.find('[data-auto-type]').each(function(idx, item){
                    $(item).on('init', function(event, slick){
                        var $this = $(this);

                        var _type = $this.data('autoType');
                        var $container = $this.closest('[data-role="slide-container"]');
                        var _count = $('<div class="slide-count"><strong></strong><span></span></div>');
                        var _play = $('<button type="button" class="btn-play"><em class="blind">멈춤</em></button>');
                        var _prev = $('<button class="btn-prev"><em class="blind">이전 페이지</em></button>');
                        var _next = $('<button class="btn-next"><em class="blind">다음 페이지</em></button>');
    
                        if( _type == "button") {
                            //히어로
                            var isService = $this.hasClass('main-service-slider');
                            if(isService){
                                _count.prepend(_play);
                                
                            }
                            //공지사항
                            var isNotice = $this.hasClass('notice-slider');
                            if(isNotice) {
                                _count.prepend(_prev);
                                _count.prepend(_play);
                                _count.append(_next);
                            }

                            //가전꿀팁
                            var isRelated = $this.hasClass('related-slider');
                            if(isRelated) {
                                _count.prepend(_prev);
                                _count.prepend(_play);
                                _count.append(_next);
                            }

                            _count.insertAfter($this);
                        }

                        if(slick.$slides.length > slick.options.slidesToScroll) {
                            $container.find('.slide-count').show();
                            $container.find('.btn-play').show();
                        } else {
                            $container.find('.slide-count').hide();
                            $container.find('.btn-play').hide();
                        }
                    });
                });


                //히어로 슬라이드
                $(self.service.el.slider)
                .on('init', function(event, slick){
                    // autoplay
                    if(!self.service.config.autoplay) {
                        $('.main-service-wrap').find('.btn-play').addClass('pause');
                    }
                    $('.main-service-wrap').find('.slide-count strong').html(1);
                    $('.main-service-wrap').find('.slide-count span').html(' / ' + slick.slideCount);
                })
                .on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    $('.main-service-wrap').find('.slide-count strong').html(nextSlide+1);
                    $('.main-service-wrap').find('.slide-count span').html(' / ' + slick.slideCount);
                })
                .slick(self.service.config);


                //공지사항 슬라이드
                $(self.notice.el.slider).not(self.activeClass)
                .on('init', function(event, slick){
                    // autoplay
                    if(!self.notice.config.autoplay) {
                        $('.home-notice-wrap').find('.btn-play').addClass('pause');
                    }
                    $('.home-notice-wrap').find('.slide-count strong').html(1);
                    $('.home-notice-wrap').find('.slide-count span').html(' / ' + slick.slideCount);
                })
                .on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    $('.home-notice-wrap').find('.slide-count strong').html(nextSlide+1);
                    $('.home-notice-wrap').find('.slide-count span').html(' / ' + slick.slideCount);
                })
                .slick(self.notice.config);


                // 가전꿀팁 슬라이드
                $(self.related.el.slider).not(self.activeClass)
                .on('init', function(event, slick){
                    // autoplay
                    if(!self.related.config.autoplay) {
                        $('.related-pages-wrap').find('.btn-play').addClass('pause');
                    }
                    $('.related-pages-wrap').find('.slide-count strong').html(1);
                    $('.related-pages-wrap').find('.slide-count span').html(' / ' + slick.slideCount);
                })
                .on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    $('.related-pages-wrap').find('.slide-count strong').html(nextSlide+1);
                    $('.related-pages-wrap').find('.slide-count span').html(' / ' + slick.slideCount);
                })
                .slick(self.related.config);



                // 히어로 재생/정지 버튼
                $(".main-service-wrap .btn-play").on("click", function () {
                    if ($(this).hasClass("pause")) {
                        $(this).removeClass("pause");
                        $(self.service.el.slider).slick("slickPlay");
                        $(".main-service-wrap .btn-play span").text("정지");
                    } else {
                        $(this).addClass("pause");
                        $(self.service.el.slider).slick("slickPause");
                        $(".main-service-wrap .btn-play span").text("재생");
                    }
                });

                // 공지사항 재생/정지 버튼
                $(".home-notice-wrap .btn-play").on("click", function () {
                    if ($(this).hasClass("pause")) {
                        $(this).removeClass("pause");
                        $(self.notice.el.slider).slick("slickPlay");
                        $(".home-notice-wrap .btn-play span").text("정지");
                    } else {
                        $(this).addClass("pause");
                        $(self.notice.el.slider).slick("slickPause");
                        $(".home-notice-wrap .btn-play span").text("재생");
                    }
                });
                $(".home-notice-wrap .btn-prev").on("click", function () {
                    $(self.notice.el.slider).slick("slickPrev");
                });
                $(".home-notice-wrap .btn-next").on("click", function () {
                    $(self.notice.el.slider).slick("slickNext");
                });

                // 가전꿀팁 재생/정지 버튼
                $(".related-pages-wrap .btn-play").on("click", function () {
                    if ($(this).hasClass("pause")) {
                        $(this).removeClass("pause");
                        $(self.related.el.slider).slick("slickPlay");
                        $(".related-pages-wrap .btn-play span").text("정지");
                    } else {
                        $(this).addClass("pause");
                        $(self.related.el.slider).slick("slickPause");
                        $(".related-pages-wrap .btn-play span").text("재생");
                    }
                });
                $(".related-pages-wrap .btn-prev").on("click", function () {
                    $(self.related.el.slider).slick("slickPrev");
                });
                $(".related-pages-wrap .btn-next").on("click", function () {
                    $(self.related.el.slider).slick("slickNext");
                });
                


            },
            refresh : function(){
                $context.find('.slick-initialized').slick('refresh');
            }
        },
        reservation : {
            serviceReserv : {
                el : {
                    container : $context.find('.service-reserv'),
                    agreeChk : $context.find('#agreePrivacyCheck'),
                    popup : $context.find('#agreePrivacyPopup'),
                },
                validation : null,
                addressFinder : null,
                validateInit : function(){
                    var self = this;
                   
                    vcui.require(['ui/validation', 'ui/formatter'], function () {
                        self.addressFinder = new AddressFind();
                        //$('#servicePhoneNo').vcFormatter({'format':'num', "maxlength":11});
        
                        var register = {
                            agreePrivacyCheck : {
                                required : true,
                                msgTarget : ".err-block"
                            },

                            serviceUserName : {
                                required: true,
                                maxLength: 30,
                                pattern: /^[가-힣\s]+$|^[a-zA-Z\s]+$/,
                                msgTarget: '.err-block',
                                errorMsg: '이름을 입력해주세요.',
                                patternMsg: '이름은 한글 또는 영문으로만 입력해주세요.'
                            },
                            servicePhoneNo : {
                                required : true,
                                minLength : 10,
                                maxLength : 11,
                                pattern: /^(010|011|17|018|019)\d{3,4}\d{4}$/,
                                msgTarget : ".err-block",
                                validate : function(value){
                                    return validatePhone(value);
                                } 
                            }
                        };
                        self.validation = new vcui.ui.CsValidation('.service-reserv', {register : register});
                    });
                    
                },
                inputVisible : function(){
                    var self = this;
                    self.el.container.find('#serviceUserName, #servicePhoneNo, .btn-reservation').prop('disabled', false).val('');
                    self.el.container.find('.btn-reservation').removeClass('disabled');
                },
                inputDisable : function(){
                    var self = this;
                    self.el.container.find('#serviceUserName, #servicePhoneNo, .btn-reservation').prop('disabled', true).val('');
                    self.el.container.find('.btn-reservation').addClass('disabled');
                },
                //BTOCSITE-9066 추가
                inputCssChk : function(){
                    //210113 추가 - 수정 필요
                    var self = this;
                    var formHead = self.el.container.find('.form-head');
                    var formHeadErrBlock = formHead.find('.err-block');

                    if(vcui.detect.isMobileDevice){
                        if(formHeadErrBlock.css('display') === 'none' && formHeadErrBlock.hasClass('active')){
                            formHead.css('margin-bottom','45px');
                        } else {
                            formHead.css('margin-bottom','24px');
                            formHeadErrBlock.addClass('active');
                        }
                    }
                },
                init : function(){
                    var self = this;

                    self.validateInit();

                    // self.el.agreeChk.on('change', function(e){
                    //     var $this = $(this);
                    //     var _checked = $this.prop('checked');

                    //     if( _checked ) {
                    //         self.inputVisible();
                    //     } else {
                    //         self.inputDisable();
                    //     }
                    // });
                    //BTOCSITE-9066 추가
                    self.el.agreeChk.on('change',function (e){
                        self.inputCssChk();
                    })

                    self.el.popup.find('.btn-agree').on('click', function(e){
                        e.preventDefault();

                        if( self.el.agreeChk.prop('checked') == false) {
                            self.el.agreeChk.trigger('click');
                        }
                    });
                    

                    self.el.container.find('.btn-reservation').on('click', function(){
                        self.validation.validate();
                        var validationResult = self.validation.validate().success;
                        if( validationResult ) {
                            //각 인풋의 value를 히든 인풋에 담은 뒤에 서브밋
                            
                            $context.find('#userName').val($context.find('#serviceUserName').val());
                            $context.find('#userPhoneNo').val($context.find('#servicePhoneNo').val())
                            $context.find('#userZipCode').val($context.find('#serviceZipCode').val())
                            $context.find('#userAddress').val($context.find('#serviceUserAddress').val())
                            $context.find('#userDetailAddress').val($context.find('#serviceDetailAddress').val())
                            
                            lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainEngineerClick.do', '/acecount/mainEngineerClickm.do');
                            self.el.container.find('#serviceReservationForm').submit();
                        }
                    });

                    // 20210727 BTOCSITE-3577 고객지원 > Main 의 출장서비스 예약 영역에 제품 설치/철거 예약 탭 및 내용 추가
                    self.el.container.find('.btn-installDemolition').on('click', function(){
                        var _self = this;
                        var href = $(this).data("href");
                        var target = $(this).data("target");

                        lgkorUI.confirm( "제품 이전 설치/철거 서비스는 <b>전문업체인</b><br> <b>(주)엘엑스판토스 홈페이지를 통해 예약이<br> 가능합니다.</b><br><br><b>(주)엘엑스판토스로 이동 하시겠습니까?</b>",{
                            typeClass:'type2',
                            title:'',
                            okBtnName: '네',
                            cancelBtnName: '아니요',
                            ok: function() {
                                if(href)  {
                                    if(target) {
                                        window.open(href, '_blank');
                                    } else {
                                        location.href = href;
                                    }
                                } 
                            }
                        });
                    });

                    self.el.container.find('.btn-address').on('click', function() { 
                        self.addressFinder.open(function(data) { 
                            var address = data.userSelectedType == 'R' ? data.roadAddress : data.jibunAddress;
        
                            self.el.container.find('#serviceZipCode').val(data.zonecode);
                            self.el.container.find('#serviceUserAddress').val(address);
                            self.el.container.find('#serviceDetailAddress').val('').prop('disabled', false);
                        }); 
                    });
                }
            },
            reservInquiry : {
                el : {
                    container : $context.find('.reserv-inquiry'),
                    authChangeRdo : '[name="inquiryRdo"]',
                    changeCont : $context.find('.reserv-inquiry .toggle-forms')
                },
                validation : null,
                authManager : null,
                validateInit : function(){
                    var self = this;
                   
                    vcui.require(['ui/validation', 'ui/formatter'], function () {
                        $context.find('#inquiryAuthPhoneNo').vcFormatter({'format':'num', "maxlength":11});
        
                        var register = {
                            userName1 : {
                                required: true,
                                maxLength: 30,
                                pattern: /^[가-힣\s]+$|^[a-zA-Z\s]+$/,
                                msgTarget: '.err-block',
                                errorMsg: '이름을 입력해주세요.',
                                patternMsg: '이름은 한글 또는 영문으로만 입력해주세요.'
                            },
                            number : {
                                required: true,
                                msgTarget: '.err-block',
                                errorMsg: '접수번호를 입력해주세요.'
                            }
                        };

                        var authOptions = {
                            elem: {
                                form: '#authDataForm2',
                                name: '#userName2',
                                phone: '#phoneNo',
                                number: '#authNo'
                            },
                            register: {
                                userName2: {
                                    required: true,
                                    maxLength: 30,
                                    pattern: /^[가-힣\s]+$|^[a-zA-Z\s]+$/,
                                    msgTarget: '.err-block',                        
                                    errorMsg: '이름을 입력해주세요.',
                                    patternMsg: '이름은 한글 또는 영문만 입력 가능합니다.'
                                },
                                phoneNo: {
                                    required: true,
                                    minLength: 10,
                                    maxLength: 11,
                                    msgTarget: '.err-block',
                                    errorMsg: '정확한 휴대폰번호를 입력해주세요.',
                                    patternMsg: '정확한 휴대폰번호를 입력해주세요.',
                                    validate : function(value){
                                        return validatePhone(value);
                                    } 
                                },
                                authNo:{
                                    required: true,
                                    msgTarget: '.err-block',
                                    errorMsg: '인증번호를 입력해주세요.',
                                }
                            },
                            pass: false
                        };

                        if( self.el.container.find('.form-wrap').length) {
                            self.validation = new vcui.ui.CsValidation('.reserv-inquiry .auth-type-no', {register : register});
                            self.authManager = new AuthManager(authOptions);
    
                            self.el.container.find('.btn-auth-confirm').on('click', function() {
                                self.authManager.send(this);
                                //self.el.container.find('#authNo').attr('disabled', false);
                            });
    
                            self.el.container.find('.btn-inquiry').on('click', function(){
                                var _self = this;

                                if( self.el.container.find('.auth-type-no.active').length ) {

                                    self.validation.validate();
                                    var validationResult = self.validation.validate().success;
                                    var resultData = self.validation.getAllValues();

                                    if( validationResult ) {
                                        lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainStatusClick.do', '/acecount/mainStatusClickm.do');
                                        lgkorUI.showLoading();
                                        lgkorUI.requestAjaxDataPost($context.find('#authDataForm1').data('ajax'), resultData, function(result) {
                                            if (result.data.resultFlag == 'Y') {
                                                $context.find('#authDataForm1').attr('action', result.data.url).submit();
                                            } else if (result.data.resultFlag == 'N') {
                                                lgkorUI.alert("", {
                                                    title: result.data.resultMessage,
                                                    ok: function(el) {
                                                        if (result.data.url) {
                                                            location.href = result.data.url; 
                                                        } else {
                                                            $(el).vcModal('hide');
                                                        }
                                                    }
                                                }, _self);
                                            }
                    
                                            lgkorUI.hideLoading();
                                        });
                                    }
                                }
                                
                                if( self.el.container.find('.auth-type-phone.active').length ) {
                                    self.authManager.confirm('.btn-auth-confirm', function(success) {
                                        success && lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainStatusClick.do', '/acecount/mainStatusClickm.do');
                                    });
                                }
    
                            })
                        }
                    });
                    
                },
                toggle : function(){
                    var self = this;
                    var $rdo = self.el.container.find(self.el.authChangeRdo);
                    var $toggleCont = self.el.changeCont;

                    $rdo.eq(0).prop('checked', true);
                    $toggleCont.removeClass('active').eq(0).addClass('active');
    
                    $rdo.on('change', function(e){
                        var curValue = parseInt(this.value);

                        $toggleCont.removeClass('active').eq(curValue).addClass('active');
                        switch(curValue) {
                            case 0 : 
                            
                            case 1 : 
                        }
                        $toggleCont.eq(this.value)
                    })
                },
                init :function(){
                    var self = this;
                    self.toggle();

                    self.validateInit();
                    
                    // 2021-07-27 BTOCSITE-3604 문의/예약 조회 GET 을 POST로 변경 : 추가
                    $(document).on('click', '.reserv-inquiry-slider .item-list a', function(e){
                        var $this = $(this);
                        var currentData = $this.data();
                        var $form = $this.closest('.rersv-wrap').find('#reservInquiryForm');

                        e.preventDefault();

                        if( currentData ) {
                            $.each(currentData, function(key, value){
                                if( value ) {
                                    if( key == 'username') {
                                        $form.find('#username1').val(value)
                                    } else {
                                        $form.find('#' + key).val(value)
                                    }
                                }
                            })
                        }
                        $form.submit();
                    })
                }
            },
            init : function(){
                this.serviceReserv.init();
                this.reservInquiry.init();
            }
        },
        // BTOCSITE-7261 뷰저블 쿼리셀렉터 이슈 해결 (CS)
        getRegisterdProduct : {
            el : {
                container :'.popular-list-wrap',
                toggleBtn : '.btn-filter-toggle',
                listWrap : '.popular-list-wrap',
                pdCont : '.solution-pd-content',
            },
            template : 
                '{{#each (item, index) in listData}}' + 
                '{{#if (index < 3)}}' + 
                '<div class="item-list">' +
                '{{#else}}' + 
                '<div class="item-list" data-more="hidden">' +
                '{{/if}}'+
                    '<a href="/support/solutions-{{item.item_id}}?category={{item.parent_category}}&subCategory={{item.category}}" id="beu_cst_sc_{{item.item_id}}">' + 
                        '<div class="item-category"><span class="category-thumb"><img src="{{item.icon_path}}" alt=""></span> {{item.cate_name}}</div>' + 
                        '<strong class="item-tit">{{item.item_title}}</strong>' + 
                        '<ul class="bullet-list">' + 
                            '<li class="b-txt">{{item.parent_cate_name}} > {{item.cate_name}}</li>' + 
                            '<li class="b-txt">{{item.symp_name}} > {{item.symp_sub_name}}</li>' + 
                        '</ul>' + 
                    '</a>' + 
                '</div>' + 
                '{{/each}}',
            getProduct : function(){
                var self = this;
                var $container = $(self.el.container);
                var $pdCont = $container.find(self.el.pdCont);
                var ajaxUrl = $container.data('ajax');
                var modelCnt = $context.find('[name="modelCnt"]').val();
                var memberContentsCnt = $context.find('[name="memberContentsCnt"]').val();
                var alertMsgArry = [
                    '등록된 제품이 없습니다. <br>보유제품을 등록하시겠습니까?',
                    '보유하신 제품으로 검색된 결과가 없습니다.'
                ];
                var alertMsg = alertMsgArry[0];
                if( modelCnt == 0) {
                    alertMsg = alertMsgArry[0];
                    lgkorUI.confirm(alertMsg,{
                        typeClass:'type2',
                        title:'',
                        okBtnName: '네',
                        cancelBtnName: '아니요',
                        ok: function() {
                            location.href = "/my-page/manage-products";
                        },
                        cancel: function() {
                            // BTOCSITE-28887 [요금/보증안내] 모니터 보증기간 제품 추가 - 체크박스 해제
                            $("#myPrdChkfilter").prop("checked", false);
                        }
                    });
                } else if ( modelCnt > 0 && memberContentsCnt == 0) {
                    alertMsg = alertMsgArry[1];
                    lgkorUI.alert(alertMsg,{
                        typeClass:'type2',
                        title:''
                    });
                } else { 
                    lgkorUI.showLoading();
                    lgkorUI.requestAjaxData(ajaxUrl, {}, function(result) {
                        var data = result.data,
                            listData = data.listData,
                            html;
                          
                            if( listData.length ) {
                                html = vcui.template(self.template, data); 
                                $pdCont.filter('.registerd-pd').find(self.el.listWrap).html(html);   
                                $pdCont.filter('.registerd-pd').addClass('active').siblings().removeClass('active').find('.btn-moreview').removeClass('close').text('더보기');;
                                $(self.el.toggleBtn).addClass('active');
                                $("#myPrdChkfilter").prop("checked", true); //BTOCSITE-9066 추가
                                supportHome.moreShow.btnShow();
                                lgkorUI.hideLoading();
                            } else {
                                lgkorUI.hideLoading();
                                lgkorUI.confirm(alertMsg,{
                                    typeClass:'type2',
                                    title:'',
                                    okBtnName: '네',
                                    cancelBtnName: '아니요',
                                    ok: function() {
                                        location.href = data.url;
                                    },
                                    cancel: function() {
                                        
                                    }
                                });
                            }
                    });
                }
                    

            },
            init : function(){
                var self = this;
                var $container = $context.find(self.el.container);
                var $listWrap = $container.find(self.el.listWrap);
                var $pdCont = $container.find(self.el.pdCont);
                var modelCnt = $context.find('[name="modelCnt"]').val();
                var memberContentsCnt = $context.find('[name="memberContentsCnt"]').val();

                if( memberContentsCnt > 0 && modelCnt > 0) {
                    self.getProduct();
                }

                $listWrap.on('click', 'a', function() {
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainKeywordResetClick.do', '/acecount/mainKeywordResetClickm.do');
                });
                
                $container.find(self.el.toggleBtn).on('click', function(e){
                    var $this = $(this);
                    // BTOCSITE-9066 신규 WSG 적용 - 고객지원_prop checkd 속성 추가 - s
                    if( lgkorUI.isLogin ) {
                        e.preventDefault();
                        if( $this.hasClass('active') ) {                            
                            $this.removeClass('active');
                            $pdCont.filter('.default-pd').addClass('active').siblings().removeClass('active').find('.btn-moreview').removeClass('close').text('더보기');
                            $("#myPrdChkfilter").prop("checked", false);
                        } else {
                            $("#myPrdChkfilter").prop("checked", true);
                            self.getProduct();
                        }
                    // BTOCSITE-9066 신규 WSG 적용 - 고객지원_prop checkd 속성 추가 - e
                    } else {
                        //비로그인

                        var _url = $this.data('href');

                        lgkorUI.confirm('로그인을 하셔야 이용하실 수 있습니다. <br>로그인 하시겠습니까?',{
                            typeClass:'type2',
                            title:'',
                            okBtnName: '네',
                            cancelBtnName: '아니요',
                            ok: function() {
                                location.href = _url;
                                
                            },
                            cancel: function() {
                                //BTOCSITE-9066 신규 WSG 적용 - prop checked false
                                $("#myPrdChkfilter").prop("checked", false);
                                
                            }
                        }, $this[0]);
                    }
                })
                
            }
        },
        modal : {
            el : {
                modal : '<div class="ui_modal_wrap init-type" style="position:fixed; z-index:9000; top:0; left:0; width:100%; height:100%;"/>',
                popup : '.popup-init',
                check : '[data-role="today-cookie-check"]',
                close : '.btn-close'
            },
            init : function(){
                var self = this;
                var $popup = $(self.el.popup);
                
                if($popup.length ) {
                    $popup.each(function(v, i){
                        var $this = $(this);
                        var _id = $this.attr('id');

                        if( lgkorUI.cookie.getCookie(_id) == "done") {
                            $this.addClass('hidden');
                        }
                    })
                    $popup.not('.hidden').addClass('active').attr('tabindex', '0');

                    if( $(".ui_modal_wrap.init-type").length == 0 && $popup.filter('.active').length ) {
                        $popup.filter('.active').wrapAll(self.el.modal);
                        $popup.filter('.active').stop().fadeIn();
                        $popup.filter('.active').first().focus();

                        if( !vcui.detect.isMobileDevice) {
                            $popup.filter('.active').not('.mCustomScrollbar').find('.pop-conts').mCustomScrollbar();
                            $popup.filter('.active').not('.mCustomScrollbar').find('.video-figure').mCustomScrollbar();
                        }
                    }
                }



                $popup.find(self.el.close).on('click', function(e){
                    var $this = $(this);
                    var $curModal = $this.closest('.popup-init');
                    var $modalWrap = $this.closest('.ui_modal_wrap');
                    var $dimm = $modalWrap.find('.ui_modal_dim');
                    var $check = $curModal.find(self.el.check).find('input:checkbox');
                    var _id = $curModal.attr('id');

                    if( $check.prop('checked')) {
                        lgkorUI.cookie.setCookie(_id, "done", 1);
                    }
                    
                    if( $modalWrap.find('.popup-init.active').length == 1) {
                        $modalWrap.stop().fadeOut(function(){
                            //$popup.unwrap();
                            $curModal.hide().removeClass('active');
                            $('html').css('overflow', 'visible');
                        });
                    } else {
                        $curModal.stop().fadeOut(function(){
                            $(this).removeClass('active');
                        })
                    }
                    e.preventDefault();
                });

                var $elFocus = $('.ui_modal_wrap.init-type').find('a, button, input, textarea').filter(':visible');

                $('.ui_modal_wrap.init-type .ui_modal_dim').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
                })

                // $elFocus.first().css('color', 'red');
                // $elFocus.last().css('color', 'blue');

                $popup.filter('.active').first().on('keydown', function(e){
                    if( e.shiftKey && e.keyCode == 9) {
                        if( $(e.target).is('.popup-init') ) {
                            e.preventDefault();
                            $elFocus.last().focus();
                        }
                    }
                })

                $elFocus.last().on('keydown', function(e){
                    if( !e.shiftKey && e.keyCode == 9) {
                        e.preventDefault();
                        $elFocus.first().focus();
                    }
                })
            }
        },
        keyword: {
            el: {
                searchWrap: '.ui_search',
                searchInput: '.ui_search #search',
                recentlyWrap: '.recently-keyword',
                popularWrap: '.popular-keyword',
                recommendWrap: '.search-recommend',
                autocompleteWrap: '.autocomplete-box'
            },
            init: function() {
                if (!$context.find('.ui_search').length) return;

                var self = this;
                var $searchWrap = $context.find(self.el.searchWrap);
                var $searchInput = $context.find(self.el.searchInput);
                var $recentlyWrap = $context.find(self.el.recentlyWrap);
                var $popularWrap = $context.find(self.el.popularWrap);
                var $autocompleteWrap = $context.find(self.el.autocompleteWrap);
                // var $recommendWrap = $context.find(self.el.recommendWrap);
                var url = $searchWrap.data('searchUrl');

                $searchInput.on('keyup', function(e) {
                    if (e.keyCode == 13) {  
                        e.preventDefault();
                        var _value = $searchInput.val();
                        lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainSearchClick.do', '/acecount/mainSearchClickm.do');
                        _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                        _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
                        location.href = url + encodeURI(_value)
                    }
                });
                // $searchInput.on('focus', function(e) {
                //     $recommendWrap.hide();
                // });
                // $searchInput.on('focusout', function(e) {
                //     if(!$searchInput.val()){
                //         $recommendWrap.show();
                //     }
                // });

                $searchWrap.find('.btn-search').on('click', function() {
                    var _value = $searchInput.val();
                    if(_value){
                        _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                        _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
                    }else{
                        var _value = $searchInput.data('suggested');
                    }
                    
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainSearchClick.do', '/acecount/mainSearchClickm.do');
                    location.href = url + encodeURI(_value)
                });

                $searchWrap.on('keywordClick', function() {
                    var _value = $searchInput.val();
                    _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                    _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089

                    // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                    lgkorUI.recentlyKeyword.addCookie(_value);

                    location.href = url + encodeURI(_value)
                });

                // $searchWrap.find('.btn-close').on('click', function() {
                //     if(!$searchInput.val()){
                //         $recommendWrap.show();
                //     }
                // });

                $autocompleteWrap.on('click', 'a', function() {
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainModelClick.do', '/acecount/mainModelClickm.do');
                });

                $recentlyWrap.on('click', 'a', function() {
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainRecentClick.do', '/acecount/mainRecentClickm.do');
                });

                $popularWrap.on('click', 'a', function() {
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainPopularClick.do', '/acecount/mainPopularClickm.do');
                });
                // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                $searchWrap.search({
                    template: {
                        autocompleteList: '<li><a href="#{{input}}">{{#raw text}}</a></li>',
                        recentlyList: '<li><a href="#">{{keyword}}</a><button type="button" class="btn-delete"><span class="blind">삭제</span></button></li>',
                        keywordList: '<li><a href="#">{{keyword}}</a></li>'
                    }
                });
                // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                $searchWrap.on('autocompleteonmain', function(e, param, url, callback) {
                    lgkorUI.requestAjaxData(url, param, function(result) {
                        callback(result);
                    });
                });
            }
        },
        center: {
            el : {
            },
            
            // 현재 위치 검색
            _setCurrentSearch: function(init) {
                var self = this;
                var wpid;
                var isClose = false;
                var searchCurrentSearch = function() {
                    try {
                        if (isApp() && vcui.detect.isAndroid) {
                            var appGeoAgree = android.getLocationActive();
                            if (appGeoAgree == "Y") {
                                android.getLocation("setAppLocation");
                            } else {
                                if (navigator.geolocation) {
                                    // 안드로이드 핸드폰 설정에서 앱의 위치권한을 막을시 권한을 얻기위한 무한 대기로 watchPosition로 변경 ( timeout은 권한을 얻기 위한 시간은 제외됨 )
                                    wpid = navigator.geolocation.watchPosition(
                                        function (e) {
                                            if (!isClose) {
                                                isClose = true;
                                                onSuccessGeolocation(e);
                                                navigator.geolocation.clearWatch(wpid);
                                            }
                                        },
                                        function (e) {
                                            if (!isClose) {
                                                isClose = true;
                                                onErrorGeolocation(e);
                                                navigator.geolocation.clearWatch(wpid);
                                            }
                                        }, {timeout: 3000, frequency: 10000}
                                    );

                                    setTimeout(function () {
                                        if (!isClose) {
                                            isClose = true;
                                            onErrorGeolocation();
                                            navigator.geolocation.clearWatch(wpid);
                                        }
                                    }, 4000);
                                } else {
                                    onErrorGeolocation();
                                }
                            }
                        } else {
                            navigator.geolocation.getCurrentPosition(function (pos) {
                                onSuccessGeolocation(pos);
                            }, function (e) {
                                onErrorGeolocation(e);
                            });
                        }
                    } catch (e) {
                        setAppLocation("37.5235644,127.0395764");
                    }
                };
                var onSuccessGeolocation = function(pos){
                    self.latitude = pos.coords.latitude;
                    self.longitude = pos.coords.longitude;

                    self.searchResultMode = init ? false : true;
                    // self.schReaultTmplID = "currentSearch";

                    self._loadStoreData();
                    // self._showMap(true);
                    // !init && self._showResultLayer();
                };
                var onErrorGeolocation = function (e) {
                    var errorCodes = {
                        code1: "위치정보 권한이 없습니다.",
                        code2: "위치정보 오류가 있습니다.",
                        code3: "제한된 시간이 지났습니다.",
                    };

                    var msg;
                    if (e) {
                        msg = errorCodes["code" + e.code];
                    } else {
                        msg = "현재 위치를 찾을 수 없습니다.";
                    }

                    lgkorUI.alert(msg, {
                        title: '현재 위치 정보',
                        typeClass: 'type2',
                        ok: function() {
                            if (init) {
                                self.searchResultMode = false;

                                self.latitude = self.defaultLatitude;
                                self.longitude = self.defaultLongitude;

                                if (self.isLogin) {
                                    self._setUserAdressSearch(true);
                                } else {
                                    self._loadStoreData();
                                }
                            }
                        }
                    });
                };
                setAppLocation = function(currentLocation){
                    if (currentLocation == '') currentLocation = '37.55401,126.97486';
                    
                    var arrLocation = currentLocation.split(',');
                    self.latitude = arrLocation[0];
                    self.longitude = arrLocation[1];

                    self.searchResultMode = init ? false : true;
                    // self.schReaultTmplID = "currentSearch";

                    self._loadStoreData();    
                    // !init && self._showResultLayer();
                };

                // 앱 위치 권한 콜백
                useGps = function(appGeoAgree) {
                    if(isApp()){
                        if (appGeoAgree=='Y'){
                            if(vcui.detect.isIOS) {
                                var jsonString= JSON.stringify({
                                    command : "getGPS",
                                    callback : "setAppLocation"
                                });
                                webkit.messageHandlers.callbackHandler.postMessage(jsonString);   
                            } else {
                                android.getLocation('setAppLocation');
                            }
                            //searchCurrentSearch();
                            //setAppLocation(android.getLocation());	
                        } else {
                            if (!init) {
                                lgkorUI.alert('', {
                                    title: '설정 > 앱권한에서 위치정보 이용동의를 하셔야 이용 가능합니다.'
                                }, self.$searchCurrentButton[0]);
                            } else {
                                self.searchResultMode = false;

                                self.latitude = self.defaultLatitude;
                                self.longitude = self.defaultLongitude;
                                
                                self._loadStoreData();
                            }	
                            //setAppLocation('37.55401,126.97486');	    	        			
                        }
                    } 
                }

                var obj ={
                    title:'센터찾기 위치 정보 수집 이용 동의', 
                    typeClass:'type2', 
                    okBtnName: '동의',
                    cancelBtnName: '동의 안함',
                    ok : function (){
                        setTimeout(function () {
                            window.sessionStorage.setItem("findCenterMapAgree", "Y");
                            searchCurrentSearch();
                        }, 300);
                    },
                    cancel: function() {
                        lgkorUI.alert('현재 위치를 찾을 수 없습니다.', {
                            title: '현재 위치 정보',
                            typeClass: 'type2',
                            ok: function() {
                                if (init) {
                                    self.searchResultMode = false;

                                    self.latitude = self.defaultLatitude;
                                    self.longitude = self.defaultLongitude;
                                    
                                    if (self.isLogin) {
                                        self._setUserAdressSearch(true);
                                    } else {
                                        self._loadStoreData();
                                    }
                                }
                            }
                        });
                    }};

                // BTOCSITE-34955 위치정보 제공 동의서 수정 및 링크 추가 요청 건
                var desc = '<p style="text-align:left;">LG전자 주식회사(이하 &apos;회사&apos;라 합니다)는 고객님께서 현재 계신 위치에서 직선 거리 기준으로 가장 가까운 센터를 안내하기 위하여 위치정보를 수집하고 있습니다. <br><br>또한 상기 서비스 제공 후 즉시 폐기되며, 별도 저장되지 않습니다.<br><br>본 서비스 이용을 위해서는 <a href="https://kr.m.lgaccount.com/customer/terms_detail?country=KR&language=ko-KR&terms_svcCode=SVC709&terms_type=A_ITG_LBS&terms_publish=Y&division=noticeterms" style="text-decoration: underline;" target="_blank" data-app-target="out">&apos;LG전자 위치기반서비스 이용약관&apos;</a>의 동의가 필요합니다.<br><br>고객님의 현재 계신 위치 정보 제공 및 LG전자 위치기반서비스 이용약관에 동의하시겠습니까?</p>';

                var findCenterMapAgree = window.sessionStorage.getItem("findCenterMapAgree");
                if (findCenterMapAgree && findCenterMapAgree == "Y") {
                    searchCurrentSearch();
                } else {
                    lgkorUI.confirm(desc, obj);
                }
            },
            _loadStoreData: function(seq){
                var self = this;
                var param = {
                    latitude:self.latitude,
                    longitude:self.longitude
                }
    
                if (seq) param = $.extend(param, {
                    seq: seq
                });
    
                var url = "/support/center/selectCenterXy.lgajax";
                lgkorUI.showLoading();
                lgkorUI.requestAjaxDataPost(url, param, function(result){

                    var data = result.data[0];
                    self.setCenterDetail(data);
                    
                    lgkorUI.hideLoading();
                });
            },
            setCenterDetail : function(data){
                var self = this;
                var centerUrl = "/support/find-service-center-" + data.shopID;
                var $selectBox = self.$svcCenterWrap.find(".ui_selectbox");
                var val = $selectBox.find('option:selected').val();

                if(val == "현재 위치로 찾기") {
                    $selectBox.find("option:first-child").text(data.shopName);
                    $selectBox.find("option:first-child").attr('value', data.shopName);
                    $selectBox.find("option:first-child").attr('data-shop-id', data.shopID);
                    $selectBox.vcSelectbox('update');
                    $selectBox.vcSelectbox('selectedIndex', 0);
                    self.$svcCenterWrap.find('.btn-center-detail').attr('href', centerUrl);
                    self.$svcCenterWrap.find('.btn-center-detail').attr('title', data.shopName);
                }

            },
            init : function(){
                var self = this;
                self.$svcCenterWrap = $context.find('.login-info-wrap .center-area');
                var selectBox = self.$svcCenterWrap.find(".ui_selectbox").vcSelectbox('instance');
                $(selectBox).siblings('.ui-selectbox-wrap').find('.ui-select-button').attr('data-contents', '선택 열기');


                //센터 상세보기
                self.$svcCenterWrap.find(".ui_selectbox").on("change", function(e){
                    var $this = $(this);
                    var val = $this.find('option:selected').val();

                    if(val == "현재 위치로 찾기") {
                        self._setCurrentSearch();
                    }
                }).on('selectboxopen', function(e){
                    $(selectBox).siblings('.ui-selectbox-wrap').find('.ui-select-button').attr('data-contents', '선택 닫기');
                }).on('selectboxclose', function(e){
                    $(selectBox).siblings('.ui-selectbox-wrap').find('.ui-select-button').attr('data-contents', '선택 열기');
                });

                self.$svcCenterWrap.find(".btn-center-detail").on("click", function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    var windowHeight = $(window).height();
                    var currentUrl = $(this).attr("href");

                    window.open(currentUrl, "_blank", "width=1070, height=" + windowHeight + ", location=no, menubar=no, status=no, toolbar=no, scrollbars=1");
                });
            }
        },
        bindEvent: function() {
            var self = this;
            self.$ownProductWrap = $context.find(".own-product-wrap");
            self.$reservWrap = $context.find(".recently-reserv-wrap"); //최근 이용 서비스
            self.$reservPopup = $context.find("#reReservationPopup"); //다시 예약 팝업

            //소모품 장바구니
            self.$ownProductWrap.find('.product-content').on('click', '.btn-cart', function(e){
                e.preventDefault();
                // var sendflag = (typeflag == "PRODUCT" || typeflag == "DISPOSABLE") ? "P" : "C";
                if(!$(this).hasClass('disabled')){
                    var $this = $(this);
                    var param = {
                        "id":$this.attr('data-id'),
                        "sku":$this.attr('data-sku'),
                        "rtSeq":'',
                        "typeFlag": "P"
                    }
                    var ajaxUrl = "/mkt/commonModule/addCart.lgajax";
                    lgkorUI.requestCart(ajaxUrl, param, true);
                }
            });

            //서비스 다시 예약
            self.$reservWrap.find('.btn-re-reserv').on("click", function(e){
                self.reReservData =  $(this).data();
                var svcType = self.reReservData.svcType;
                var txtSvc = "";
                
                switch(svcType) {
                    case "A":
                        txtSvc = "<em>출장</em>서비스를"
                        break;
                    case "B":
                        txtSvc = "<em>센터</em>방문을"
                        break;
                    default:
                        txtSvc = "<em>상담</em>예약을"
                        break;
                }
                self.$reservPopup.find('dt .txt-cate').text(self.reReservData.subCategoryNm);
                self.$reservPopup.find('dt .txt-topic').text(self.reReservData.topicNm);
                self.$reservPopup.find('dt .txt-svc').html(txtSvc);
                self.$reservPopup.find('dd .txt-topic').html(self.reReservData.topicNm + " &gt; " + self.reReservData.subTopicNm + " &gt; " + self.reReservData.threeTopicNm);
            });


            //서비스 다시 예약 팝업 - 확인
            self.$reservPopup.find(".btn-re-reservation").on("click", function(e){
                
                var val =  self.$reservPopup.find("[name=reservType]:checked").val();
                var href = "";
                var param = "?categoryNm="+ self.reReservData.categoryNm +
                    "&subCategoryNm="+ self.reReservData.subCategoryNm +
                    "&category="+ self.reReservData.category +
                    "&subCategory="+ self.reReservData.subCategory +
                    "&sympCd="+ self.reReservData.topic +
                    "&sympSubCode="+ self.reReservData.subTopic +
                    "&sympCodeThree="+ self.reReservData.threeTopic +
                    "&sympCdNm="+ self.reReservData.topicNm +
                    "&sympSubCodeNm="+ self.reReservData.subTopicNm +
                    "&sympCodeThreeNm="+ self.reReservData.threeTopicNm +
                    "&productCode="+ self.reReservData.productCd +
                    "&mktModelCd="+ self.reReservData.modelCd +"&cstFlag=Y";

                var svcType = self.reReservData.svcType;
                switch(svcType) {
                    case "A":
                        href = "/support/service-engineer-reservation";
                        break;
                    case "B":
                        href = "/support/visit-center-reservation";
                        break;
                    default:
                        href = "/support/request-call-reservation";
                        break;
                }

                if(val == 'Y'){
                    href = href + param;
                }else{
                    href = href;
                }
                self.$reservPopup.vcModal('close');
                location.href =  href;
            });
        },
        initialize: function(){
            var _this = this;

            //_this.loginTooltip();
            _this.slide.init();
            _this.keyword.init();
            _this.modal.init();
            _this.center.init();
            _this.bindEvent();


            if (lgkorUI.searchParamsToObject('smq') == 'Y') {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainThinqView.do', '/acecount/mainThinqViewm.do');
            }
        }
    }

    $(window).ready(function(){
        supportHome.initialize();    

        global[script.name] = true; // 중복 로딩 체크
    })
})(window);